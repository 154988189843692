import { useRouter } from "next/router";

import KButton from "@kikoff/components/src/v1/buttons/KButton";

import { l10nResources } from "@util/l10n";

import styles from "./money_back_guarantee.module.scss";

const l10n = l10nResources({
  en: `title = 45-day money back guarantee
description = We're confident Kikoff can help you build better credit. If our service does not meet your expectation, cancel within the first 45 days and receive your money back.
`,
  es: `title = Garantía de devolución de dinero de 45 días
description = Estamos seguros de que Kikoff puede ayudarlo a generar un mejor crédito. Si nuestro servicio no cumple con sus expectativas, cancele dentro de los primeros 45 días y reciba su dinero de vuelta.
`,
});
function MoneyBackGuarantee() {
  const router = useRouter();

  return (
    <section className={styles["money-back-guarantee-section"]}>
      <div className="color:moderate mb-4 text:heading-3">
        {l10n.getString("title")}
      </div>
      <div
        className={`mb-4 text:large color:moderate ${styles["contained-width"]}`}
      >
        {l10n.getString("description")}
        <sup>2</sup>
      </div>
      <KButton
        onClick={() => {
          router.push("/onboarding");
        }}
        size="standard"
        variant="black"
      >
        Sign up
      </KButton>
    </section>
  );
}

export default MoneyBackGuarantee;
