import React, { useEffect, useRef } from "react";

import { createDragHandler } from "@kikoff/client-utils/src/dom";
import { combineClasses } from "@kikoff/utils/src/string";

import styles from "./marquee.module.scss";

interface MarqueeProps {
  className?: string;
  speed?: number;
  acceleration?: number;
  changeWidth?: boolean;
  draggable?: boolean;
}

const Marquee: React.FC<MarqueeProps> = ({
  className,
  children,
  speed = 5,
  acceleration = 5,
  changeWidth = false,
  draggable = false,
}) => {
  /* eslint-disable no-param-reassign */
  speed /= -100;
  acceleration /= 1000;
  /* eslint-enable no-param-reassign */

  const first = useRef<HTMLDivElement>(null);
  const second = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let lastTime: number;
    let width = first.current.scrollWidth;
    const pos = {
      x: -width,
      dx: speed,
      ddx: acceleration,
    };
    requestAnimationFrame(function frame(time) {
      // Component is unmounted if first.currrent is falsey
      if (!first.current) return;
      if (changeWidth) width = first.current.scrollWidth;
      const translate = pos.x % width;

      first.current.style.transform = `translateX(${
        translate + (Math.floor(pos.x / width) % 2 ? 0 : width)
      }px)`;
      second.current.style.transform = `translateX(${
        translate + (Math.floor(pos.x / width) % 2 ? width : 0)
      }px)`;

      if (lastTime) {
        pos.x =
          ((pos.x + (time - lastTime) * pos.dx) % width) - width ||
          // Set to zero if falsey to prevent NaN if width is 0
          0;
        if (pos.dx !== speed)
          pos.dx = Math[pos.dx > speed ? "max" : "min"](
            pos.dx +
              pos.ddx *
                (time - lastTime) *
                ((speed - pos.dx) / 2 +
                  Math.sign(speed - pos.dx) * 2 * pos.ddx),
            speed
          );
      }

      lastTime = time;
      requestAnimationFrame(frame);
    });

    if (draggable) {
      let ix;
      const handlers = {
        onStart() {
          ix = pos.x;
          pos.dx = 0;
          pos.ddx = 0;
        },
        onDrag({ pos: _pos }) {
          pos.x = _pos.dx + ix;
        },
        onDrop({ pos: _pos }) {
          pos.dx = _pos.ddx;

          pos.ddx = acceleration;
        },
      };
      const cleanups = [
        createDragHandler({
          ref: first,
          ...handlers,
        }).cleanup,
        createDragHandler({
          ref: second,
          ...handlers,
        }).cleanup,
      ];
      return () => {
        for (const cleanup of cleanups) cleanup();
      };
    }
  }, []);

  return (
    <div className={combineClasses(styles.marquee, className)}>
      <div ref={first}>{children}</div>
      <div ref={second}>{children}</div>
    </div>
  );
};
export default Marquee;
