import { FluentResource } from "@fluent/bundle";
import { LocalizationProvider, Localized } from "@fluent/react";

import { newReactLocalization } from "@util/l10n";

import businessInsider from "./business_insider.png";
import cnbc from "./cnbc.png";
import nerdwallet from "./nerdwallet.png";
import pennyHoarder from "./penny_hoarder.png";

import styles from "./styles.module.scss";

const RESOURCES = {
  en: new FluentResource(`quotes-seen-on = As seen in
`),
  es: new FluentResource(`quotes-seen-on = Como se vio en
`),
};

function Publications() {
  const l10n = newReactLocalization(RESOURCES);

  return (
    <section className={styles["publication-section"]}>
      <LocalizationProvider l10n={l10n}>
        <div className={`${styles["logos-seen-on"]} text:large+`}>
          <Localized id="quotes-seen-on" />
        </div>
        <div className={styles["social-proof-section"]}>
          <div className={styles["social-proof-wrapper"]}>
            <img
              className={styles["company-logo"]}
              src={nerdwallet.src}
              alt="Nerdwallet"
            />
          </div>
          <div className={styles["social-proof-wrapper"]}>
            <img className={styles["company-logo"]} src={cnbc.src} alt="CNBC" />
          </div>
          <div className={styles["social-proof-wrapper"]}>
            <img
              className={styles["company-logo"]}
              src={businessInsider.src}
              alt="Business Insider"
            />
          </div>
          <div className={styles["social-proof-wrapper"]}>
            <img
              className={styles["company-logo"]}
              src={pennyHoarder.src}
              alt="the Penny Hoarder"
            />
          </div>
        </div>
      </LocalizationProvider>
    </section>
  );
}

export default Publications;
