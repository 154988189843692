import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";

import KButton from "@kikoff/components/src/v1/buttons/KButton";
import useMediaPredicate from "@kikoff/hooks/src/useMediaPredicate";
import { cls, combineClasses } from "@kikoff/utils/src/string";

import {
  EIGHTY_PERCENT_OFF,
  FIRST_MONTH_FREE,
  FREE_BASIC_DISCOUNTED_PREMIUM,
  SIX_MONTH_EIGHTY_PERCENT_OFF,
} from "@constant/promos";
import { selectIntroPromo } from "@feature/user";
import { useIntroPromoPoller } from "@src/hooks/promos";
import { track } from "@util/analytics";
import { l10nResources } from "@util/l10n";

import styles from "./styles.module.scss";

const l10n = l10nResources({
  en: `five-off = Sign up today and get $5 off your first month
first-month-free = Sign up today and get your first month FREE
eighty-percent = Sign up today and get 80% off your first month
six-month-eighty-percent = Sign up today and get 80% off your first 6 months
redeem-offer = Redeem offer
`,
  es: `five-off = Regístrate hoy y obtén un $5 de descuento en tu primer mes
first-month-free= Regístrate hoy y obtén tu primer mes GRATIS
eighty-percent= Regístrate hoy y obtén un 80% de descuento en tu primer mes
six-month-eighty-percent = Regístrate hoy y obtén un 80% de descuento en tu primeros 6 meses
redeem-offer = Canjear la oferta
`,
});

const IntroPromoBanner = () => {
  useIntroPromoPoller();

  const isMobile = useMediaPredicate("(max-width: 768px)");

  const router = useRouter();
  const promo = useSelector(selectIntroPromo());

  useEffect(() => {
    if (promo) {
      track.impression("Homepage - Intro Promo Banner", {
        promo: promo.name,
      });
    }
  }, []);

  if (!promo) {
    return null;
  }

  return (
    <div
      className={combineClasses(
        styles["intro-test-background"],
        promo && styles["show-banner"]
      )}
    >
      <div className={styles["content-wrapper"]}>
        <div className={styles["text-wrapper"]}>
          <span
            className={cls(
              isMobile ? "text:small+" : "text:large+",
              "color:container"
            )}
          >
            {(() => {
              switch (promo.name) {
                case EIGHTY_PERCENT_OFF:
                  return l10n.getString("eighty-percent");
                case SIX_MONTH_EIGHTY_PERCENT_OFF:
                  return l10n.getString("six-month-eighty-percent");
                case FIRST_MONTH_FREE:
                  return l10n.getString("first-month-free");
                case FREE_BASIC_DISCOUNTED_PREMIUM:
                default:
                  return l10n.getString("five-off");
              }
            })()}
            *
          </span>
        </div>
        <KButton
          size="small"
          variant="container"
          className={styles["redeem-offer-button"]}
          onClick={() => {
            track.tap("Homepage - Intro Promo Banner - Redeem offer", {
              promo: promo.name,
            });
            router.push("/onboarding");
          }}
        >
          {l10n.getString("redeem-offer")}
        </KButton>
      </div>
    </div>
  );
};

export default IntroPromoBanner;
