import { FluentResource } from "@fluent/bundle";

import { combineClasses } from "@kikoff/utils/src/string";

import { newReactLocalization } from "@util/l10n";

import easyPaymentProgress from "./easy_payment_progress.png";
import greenChart from "./green_chart.svg";
import topHalfOfPhone from "./iphone_top_half.png";

import styles from "./styles.module.scss";

const RESOURCES = {
  en: new FluentResource(`safe-credit-title = The safe way to build credit
safe-credit-text = We help you establish positive payment history and lower utilization, both important factors in calculating your credit score.
learn-more = Learn more
no-credit-check-title-1 = No credit check
no-credit-check-text-1 = No hard pulls required
no-credit-check-title-2 = Starts at $5/mo
no-credit-check-text-2 = Cancel anytime
no-credit-check-text-2-money-back-variant = Full refund if you cancel within the first 45 days. Cancel anytime after
easy-automatic-title = Easy & automatic
easy-automatic-text = With the optional autopay, never worry about due dates.
credit-journey-title = Track your credit journey
credit-journey-text = With our credit monitoring reports, see insights like problem areas so you stay on track to grow your credit.
`),
  es: new FluentResource(`safe-credit-title = La forma segura de generar crédito
safe-credit-text = Lo ayudamos a establecer un historial de pago positivo y una menor utilización, ambos factores importantes al calcular su puntaje crediticio.
learn-more = Aprende más
no-credit-check-title-1 = Sin verificación de crédito
no-credit-check-text-1 = No se requieren tirones duros
no-credit-check-title-2 = Comienza en $5/mes
no-credit-check-text-2 = Cancelar en cualquier momento
no-credit-check-text-2-money-back-variant = Reembolso completo si cancelas dentro de los primeros 45 días. Cancelar en cualquier momento después
easy-automatic-title = Fácil y automática
easy-automatic-text = Con el pago automático opcional, nunca se preocupe por las fechas de vencimiento.
credit-journey-title = Seguimiento de su viaje de crédito
credit-journey-text = Con nuestros informes de monitoreo de crédito, vea información como áreas problemáticas para mantenerse encaminado y hacer crecer su crédito.
`),
};

interface BlockProps {
  children: React.ReactNode;
  className?: string;
  variant?: "white" | "black" | "yellow";
}

function Block({ children, className, variant }: BlockProps) {
  return (
    <div
      className={combineClasses(
        styles["landing-block"],
        variant && styles[variant],
        className
      )}
    >
      {children}
    </div>
  );
}

function BlockRows() {
  const l10n = newReactLocalization(RESOURCES);

  return (
    <>
      <section className={styles["block-row"]}>
        <Block className={styles["safe-credit"]}>
          <div className={styles["txt-side"]}>
            <h2 className="text:heading-3++ m-0">
              {l10n.getString("safe-credit-title")}
            </h2>
            <p className="text:large">{l10n.getString("safe-credit-text")}</p>
            <div>
              <a
                className={`color:black text:large+ ${styles["learn-more-link"]}`}
                href="/credit-account"
              >
                {l10n.getString("learn-more")} 
              </a>
            </div>
          </div>
          <div className={styles["img-side"]}>
            <img alt="phone with app" src={topHalfOfPhone.src} />
          </div>
        </Block>
      </section>
      <section className={styles["block-row"]}>
        <Block className={styles["no-credit-check"]} variant="yellow">
          <header className="text:heading-2++">
            {l10n.getString("no-credit-check-title-1")}
          </header>
          <p className="text:large">
            {l10n.getString("no-credit-check-text-1")}
          </p>
          <header className="text:heading-2++">
            {l10n.getString("no-credit-check-title-2")}
          </header>
          <p className="text:large">
            {l10n.getString("no-credit-check-text-2-money-back-variant")}
          </p>
        </Block>
        <Block className={styles["easy-automatic"]}>
          <div className={styles["txt-side"]}>
            <header className="text:heading-3++">
              {l10n.getString("easy-automatic-title")}
            </header>
            <p className="text:large">
              {l10n.getString("easy-automatic-text")}
            </p>
          </div>
          <div className={styles["img-bottom"]}>
            <img alt="easy payment progress" src={easyPaymentProgress.src} />
          </div>
        </Block>
      </section>
      <section className={styles["block-row"]}>
        <Block className={styles["credit-journey"]} variant="black">
          <div className={styles["txt-side"]}>
            <header className="text:heading-3++">
              {l10n.getString("credit-journey-title")}
            </header>
            <p className="text:large">
              {l10n.getString("credit-journey-text")}
            </p>
          </div>
          <div className={styles["img-side"]}>
            <img alt="credit history" src={greenChart.src} />
          </div>
        </Block>
      </section>
    </>
  );
}

export default BlockRows;
