import VideoPlayer from "@component/video_player";

import styles from "./styles.module.scss";

function CustomerVideoSection() {
  return (
    <section className={styles["customer-video-section"]}>
      <div className={styles["customer-video-title"]}>Meet our customers</div>
      <div className={styles["customer-video-quote"]}>
        “Kikoff is the best way to build credit quickly.”
        <br />- Fredrika, a real Kikoff customer
      </div>
      <VideoPlayer
        thumbnail="https://assets.kikoff.com/public/blog-assets/2023/kikoff_stories.jpg"
        url="https://assets.kikoff.com/public/blog-assets/2023/kikoff_stories.mp4"
      />
    </section>
  );
}

export default CustomerVideoSection;
