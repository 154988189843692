import Line from "@component/line";
import { getCreditAccountMentalModelName } from "@src/constants";

import spaceshipTaxi from "./spaceship_taxi.png";

import styles from "./styles.module.scss";

function SuperchargeWithCBL() {
  return (
    <section className={styles["supercharged-cbl"]}>
      <div className={styles["taxi-wrapper"]}>
        <img src={spaceshipTaxi.src} alt="rocketship" />
      </div>
      <div className={styles["supercharge-offer"]}>
        <div className="text:large+">LIMITED TIME OFFER</div>
        <div className="text:heading-2">Supercharge your credit building</div>
        <div className="text:large">
          For a limited time, unlock a complimentary second tool, Kikoff’s
          Credit Builder Loan, when you make your first{" "}
          {getCreditAccountMentalModelName()} payment. Just one more way Kikoff
          helps you reach your credit score goals faster.
        </div>
        <div className="text:large+">Diversify your credit mix</div>
        <div className="text:large+">
          Report to all 3 credit bureaus with 2 tradelines
        </div>
        <div className="text:large+">$0 in fees</div>
      </div>
      <Line />
      <div>
        <div className="text:large+">Credit Builder Loan Comparison</div>
        <table className={styles["supercharged-table"]}>
          <tr>
            <th>
              <div className="text:large++">Kikoff</div>
            </th>
            <th>
              <div className="text:large++">Others</div>
            </th>
          </tr>
          <tr>
            <td>
              <div className="text:large++">$10/mo payments</div>
            </td>
            <td>
              <div className="text:large+">$25+/mo payments</div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="text:large++">$0/mo fees</div>
            </td>
            <td>
              <div className="text:large+">$7+/mo fees</div>
            </td>
          </tr>
        </table>
        <div className="text:large">
          At the end of the 12-month period,{" "}
          <div className="text:large++">
            you’ll get the full $120 back. That’s right, Kikoff takes $0 in
            fees.
          </div>
        </div>
        <div className="text:regular">
          *Limited time offer. Kikoff holds the $10/month payments for as long
          as the Credit Builder Loan account is open, and will return deposited
          funds upon close. Terms & conditions apply and results may vary. Like
          other credit building products, making consistent on-time payments
          makes an impact on your credit score.
        </div>
      </div>
    </section>
  );
}

export default SuperchargeWithCBL;
