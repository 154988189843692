import { FluentResource } from "@fluent/bundle";

import { newReactLocalization } from "@util/l10n";

import styles from "./styles.module.scss";

const RESOURCES = {
  en: new FluentResource(`why-join-reason-title = We’re designed to maximize credit.
why-join-reason-body = Kikoff addresses three key factors that determine 80% of your credit score.
why-join-reason-variant-one = Whether you have no credit or low credit, Kikoff can help.
why-join-reason-variant-two-black = No credit? Low credit?
why-join-reason-variant-two-green = Kikoff can help.
`),
  es: new FluentResource(`why-join-reason-title = Estamos diseñados para maximizar el crédito.
why-join-reason-body = Kikoff aborda tres factores clave que determinan el 80 % de su puntaje crediticio.
why-join-reason-variant-one = Ya sea que no tenga crédito o crédito bajo, Kikoff puede ayudar.
why-join-reason-variant-two-black = ¿Sin crédito? ¿Crédito bajo?
why-join-reason-variant-two-green = Kikoff puede ayudar.
`),
};

function Summary() {
  const l10n = newReactLocalization(RESOURCES);

  return (
    <section className={styles["summary"]}>
      <header>
        <div className={`text:heading-2++ ${styles["vibrant-summary"]}`}>
          <h2 className={`color:black mb-2 ${styles["vibrant-summary"]}`}>
            {l10n.getString("why-join-reason-variant-two-black")}
          </h2>
          <span className="color:primary">
            {l10n.getString("why-join-reason-variant-two-green")}
          </span>
        </div>
      </header>
    </section>
  );
}

export default Summary;
