import { useEffect, useState } from "react";
import { useMediaPredicate as _useMediaPredicate } from "react-media-hook";

export default function useMediaPredicate(query: string, defaultValue = false) {
  const _breakpoint = _useMediaPredicate(query);

  const [breakpoint, setMobile] = useState(defaultValue);

  useEffect(() => {
    setMobile(_breakpoint);
  }, [_breakpoint]);

  return breakpoint;
}
