import React from "react";
import ReactPlayer from "react-player/lazy";

import { cls } from "@kikoff/utils/src/string";

import playIcon from "./play_icon.svg";

import styles from "./styles.module.scss";

interface VideoPlayerProps extends React.ComponentProps<typeof ReactPlayer> {
  thumbnail?: string;
  url: string;
  variant?: "horizontal" | "vertical";
  className?: string;
}

// See here for how to upload assets to our S3, and put them through Cloudfront:
// https://www.notion.so/kikoff/Kikoff-Blog-93e2ab9fe8364b329d90cb173b8c35d5
function VideoPlayer({
  thumbnail,
  url,
  variant = "horizontal",
  className,
  ...props
}: VideoPlayerProps) {
  return (
    <div className={cls(styles[variant], styles["customer-video-content"])}>
      <ReactPlayer
        className={styles["react-player"]}
        controls
        height="100%"
        light={thumbnail}
        playIcon={<img className={styles["play-icon"]} src={playIcon.src} />}
        playing
        url={url}
        width="100%"
        {...props}
      />
    </div>
  );
}

export default VideoPlayer;
