import React from "react";

// https://developers.google.com/search/docs/advanced/robots/robots_meta_tag
function RobotsMeta() {
  if (process.env.REALLY_PRODUCTION) {
    return null;
  }

  return <meta name="robots" content="none" />;
}

export default RobotsMeta;
