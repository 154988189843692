import { FluentResource } from "@fluent/bundle";
import { LocalizationProvider, Localized } from "@fluent/react";

import { cls } from "@kikoff/utils/src/string";

import MainCta from "@component/landing/sections/hero/main_cta";
import { newReactLocalization } from "@util/l10n";

import phoneFull from "./iphone_full.png";

import styles from "./styles.module.scss";

const RESOURCES = {
  en: new FluentResource(`hero-headline = Build credit with Kikoff
hero-item-first = No credit pull required
hero-item-second = With 0% interest
hero-item-third = Open your account in minutes
money-back-hero-item = 45 day satisfaction guarantee
hero-note = Join over a million people already using Kikoff.
v-headline-start = Meet the{" "}
v-headline-end = way to build credit.
v-subline = #1 credit builder app* that's helped 1M+ users.
v-first = Get started in mins
v-second = Real results, real secure
v-third = Starts at $5/mo
v-fourth = Cancel anytime
v-option-one = smarter
v-option-two = faster
v-option-three = easier
`),
  es: new FluentResource(`hero-headline = Crea crédito con Kikoff
hero-item-first = No se requiere extracción de crédito
hero-item-second = Con 0% de interés
hero-item-third = Abre tu cuenta en minutos
money-back-hero-item = Garantía de satisfacción de 45 días
hero-note = Únete a más de un millón de personas que ya usan Kikoff.
v-headline-start = Conozca la forma
v-headline-end = de aumentar crédito.
v-subline = Aplicación de creación de crédito #1* que ha ayudado 1M+ de usuarios.
v-first = Comience en minutos
v1-second = Resultados reales, muy rápido
v2-second = Resultados reales, muy seguros
v-third = Comienza en $5/mes
v-fourth = Cancelar en cualquier momento
v-option-one = más inteligente
v-option-two = más rápida
v-option-three = más fácil
`),
};

function PhoneContainer() {
  return (
    <div className={styles["right-section"]}>
      <div className={styles["full-phone"]}>
        <img src={phoneFull.src} />
      </div>
      <div className={styles["half-phone"]} />
    </div>
  );
}

function Hero() {
  const l10n = newReactLocalization(RESOURCES);

  const getHeroItems = () => {
    const items = [
      "No credit check",
      "Open your account in minutes",
      <>
        Average of 28 point increase after the first month of Kikoff
        <sup className="text:small">1</sup>
      </>,
      l10n.getString("money-back-hero-item"),
    ];

    return items;
  };

  return (
    <section className={cls(styles["hero-section"])}>
      <LocalizationProvider l10n={l10n}>
        <div className={styles["hero-contents"]}>
          <div className={styles["left-section"]}>
            <h1
              className={cls("text:heading-3++", styles["new-hero-title"])}
              data-cy="hero-title"
            >
              <Localized id="hero-headline" />
            </h1>
            <div className={`text:large ${styles["new-hero-subtitle"]}`}>
              <Localized id="hero-note" />
            </div>
            <div className={styles["left-content"]}>
              <div className={styles["left-text"]}>
                <div className={styles["hero-items"]}>
                  {getHeroItems().map((item) => (
                    <div
                      key={`item-${item}`}
                      className={`text:large ${styles["hero-item"]}`}
                    >
                      {item}
                      {item === l10n.getString("money-back-hero-item") && (
                        <sup className="text:small">2</sup>
                      )}
                    </div>
                  ))}
                </div>
                <MainCta />
              </div>
            </div>
          </div>
          <PhoneContainer />
        </div>
      </LocalizationProvider>
    </section>
  );
}

export default Hero;
